// Include Bootstrap custom variable default overrides here
@import "../common/variables";

// Import Bootstrap utilities
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.btn-primary {
    color:white;
}

label.required:not(.no-asterisk):after {
    content: " *";
}

body {

    background-size: cover !important;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .home-block {
        @media screen and (min-width: 992px) {
            background-repeat: no-repeat;
            background-position: bottom right;
            background-size: contain;
        }
    }

    .image-text {
        @media screen and (min-width: 992px) {
            width: 80%
        }
    }

    .pagination {
        margin-right: 10px;
    }

    .aside-inscription-title {
        color:$asideInscriptionTitleColor;
    }
}

.container {
    max-width: 992px;
}

.light-gray {
    background-color: rgb(195, 195, 195);
}

.bg-black-transparent {
    background-color: rgba(0,0,0,0.4);
}

.vimeo-player {
    margin-top:-40px;
}

.custom-hr {
    border-top: solid white 1px;
    opacity:1;
}
.custom-size {
    @media screen and (min-width: 768px) {
        font-size:2rem;
    }
}

.text-sm {
    font-size:0.8rem;
}

.text-justify {
    text-align: justify;
}

.scale-up-ver-top {
    -webkit-animation: scale-up-ver-top 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-ver-top 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-ver-top {
    0% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
    }
}
@keyframes scale-up-ver-top {
    0% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
    }
}
/*
.scale-down-ver-top {
    -webkit-animation: scale-down-ver-top 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-down-ver-top 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes scale-down-ver-top {
    0% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        display:block;
    }
    100% {
        -webkit-transform: scaleY(0.3);
        transform: scaleY(0.3);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        display:none;
    }
}
@keyframes scale-down-ver-top {
    0% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        display:block;
    }
    100% {
        -webkit-transform: scaleY(0.3);
        transform: scaleY(0.3);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        display:none;
    }
}
*/
iframe {
    @media screen and (max-width: 768px) {
        height: 200px;
        max-width: 350px;
    }
}

#container-principal {

    max-width: 992px;
    position:relative;

    .block-background {
        position:absolute;
        z-index: 10;
    }

    .responsive-inscrivez-vous {
        width: 400px;
        margin:auto;
    }

    nav {
        width: 350px;
        z-index: 50;
        box-sizing: border-box;

        .toggle-container {
            height: 41px;
        }
        .nav-close {
            display:none;
            font-size:1.2rem;
        }

        @media screen and (max-width: 768px) {
            position:absolute;
            right:0;
        }

        ul {
            li {
                a {
                    color:black;
                }
                &.active {
                    background-color: $primary;
                    font-weight: bold;
                    border-color: rgba(0,0,0,.125);
                    a {
                        color:white;
                    }
                    .select-arrow {
                        display:none!important;
                    }
                }
            }
        }

        .inscrivez-vous {
            h3 {
                color : $primary;
            }
        }
    }
}

footer {

    .bg-dark-custom {

        background-color:  rgba(0 , 0 , 0, 1);

    }

    li {

        @media screen and (max-width: 992px) {
            display:block;
        }


        a {
            text-decoration: none;
            color:white;
            font-size:0.65rem;
        }
    }
    .logo-footer {
        margin-top:-122px;
        margin-right:-80px;
    }
}

.logo-footer-sm {
    width: 100px!important;
    margin-top:-36px;
    margin-right:-12px
}
.block-partenaire {
    img {
        height:50px!important;
    }
}


.impersonate-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
}
.impersonate-banner__inner {
    position: relative;
}
.impersonate-banner__inner .btn-impersonate {
    position: absolute;
    bottom: 100%;
    right: 10vw;
    color: #f8f9fa;
    background-color: rgba(33, 37, 41, 0.6);
    border: none;
    outline: none;
    padding: 2px 6px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.2rem;
}

#toggleImpersonate {
    padding: 15px;
    text-align: center;
    color: #f8f9fa;
    background-color: rgba(33, 37, 41, 0.6);
}

// carousel




